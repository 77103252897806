import { computed, effect, inject, Injectable, signal, untracked } from '@angular/core';
import { SessionService } from '../../core/services/session.service';
import { GraphqlService } from './graphql/graphql.service';
import { Restaurant, RestaurantDTO } from '../models/restaurant.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RestaurantService {
  private readonly _sessionService = inject(SessionService);
  private readonly _gql = inject(GraphqlService);
  private readonly _http = inject(HttpClient);

  private readonly _siteIds = signal<string[]>([]);
  private readonly _restaurantList = signal<Restaurant[]>([]);
  private readonly _restaurantSelected = signal<Restaurant | null>(null);
  private readonly _languagesRestSelected = computed(() => this._restaurantSelected()?.languages);
  private readonly _defaultLangRestSelected = computed(() => this._restaurantSelected()?.defaultLanguage);

  constructor() {
    effect(() => {
      const site = this._sessionService.siteIdSignal();
      untracked(() => {
        if (site.length > 0) {
          this._siteIds.set(site);
          this.fetchRestaurants();
        }
      });
    });
  }

  public get siteIds() {
    return this._siteIds.asReadonly();
  }
  public get restaurantsListSignal() {
    return this._restaurantList.asReadonly();
  }
  public get restaurantSelectedSignal() {
    return this._restaurantSelected.asReadonly();
  }
  public get languagesRestSelected() {
    return this._languagesRestSelected;
  }
  public get defaultLangRestSelected() {
    return this._defaultLangRestSelected;
  }

  public fetchRestaurants() {
    const query = `{
      fetchRestaurants
      {
        color_background
        id
        image
        languages
        name
        siteId
        defaultLanguage
        currency
      }
    }`;

    this._gql.get(query).subscribe({
      next: ({ data }) => {
        const restaurants = data.fetchRestaurants as RestaurantDTO[];
        const restMapper: Restaurant[] = restaurants.map((rest) => {
          return {
            color_background: rest.color_background ?? '#ffffff',
            id: rest.id,
            image: rest.image ?? '',
            languages: rest.languages ?? ['es', 'en'],
            defaultLanguage: rest.defaultLanguage ?? 'es',
            siteId: rest.siteId,
            name: rest.name,
            currency: rest.currency,
          };
        });
        this._restaurantList.set(restMapper);

        const restId = localStorage.getItem('restId');
        const restSelected = restMapper.find((rest) => rest.id == restId) || restMapper[0];

        this._restaurantSelected.set(restSelected);

        if (!restId || !restMapper.some((rest) => rest.id == restId)) {
          localStorage.setItem('restId', restSelected.id);
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  public changeRestaurant(rest: Restaurant) {
    this._restaurantSelected.set(rest);
    localStorage.setItem('restId', rest.id);
  }

  public updateRestaurant(restId: string, rest: Restaurant): Observable<RestaurantDTO> {
    return this._http.put<RestaurantDTO>(`${environment.apiUrl}/restaurant/${restId}`, rest);
  }
}
